import Vue from 'vue'
import Toast from 'vue-toastification'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from './i18n'

// Toast Notification Component Styles
import '@core/scss/vue/libs/toastification.scss'

/**
 * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
 */
Vue.use(Toast, {
  hideProgressBar: true,
  closeOnClick: false,
  closeButton: false,
  icon: false,
  timeout: 3000,
  transition: 'Vue-Toastification__fade',
})

const bread = {
  error: (text = i18n.t('Global.DangerText')) =>
    Vue.prototype.$toast({
      component: ToastificationContent,
      props: {
        title: i18n.t('Global.Danger'),
        variant: 'danger',
        icon: 'AlertTriangleIcon',
        text,
      },
    }),

  success: msg =>
    Vue.prototype.$toast({
      component: ToastificationContent,
      props: {
        title: i18n.t('Global.Success'),
        variant: 'success',
        icon: 'CheckIcon',
        text: msg,
      },
    }),

  create: subject =>
    Vue.prototype.$toast({
      component: ToastificationContent,
      props: {
        title: i18n.t('Global.Success'),
        variant: 'success',
        icon: 'CheckIcon',
        text: i18n.t('Global.SuccessCreate', { subject }),
      },
    }),

  update: subject =>
    Vue.prototype.$toast({
      component: ToastificationContent,
      props: {
        title: i18n.t('Global.Success'),
        variant: 'success',
        icon: 'CheckIcon',
        text: i18n.t('Global.SuccessUpdate', { subject }),
      },
    }),

  delete: subject =>
    Vue.prototype.$toast({
      component: ToastificationContent,
      props: {
        title: i18n.t('Global.Success'),
        variant: 'success',
        icon: 'CheckIcon',
        text: i18n.t('Global.SuccessDelete', { subject }),
      },
    }),

  invalid: msg =>
    Vue.prototype.$toast({
      component: ToastificationContent,
      props: {
        title: i18n.t('Global.Warning'),
        variant: 'warning',
        icon: 'AlertTriangleIcon',
        text: msg || i18n.t('Global.WarningText'),
      },
    }),
}

Vue.prototype.$alert = bread

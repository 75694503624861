import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return (
    localStorage.getItem('user')
    && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  )
}

export const logout = () => {
  localStorage.removeItem('user')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('accessToken')
}

export const getUserData = () => JSON.parse(localStorage.getItem('user'))

export const parseJwt = token => {
  if (!token) return null

  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

import Vue from "vue";
import VueRouter from "vue-router";

import { canNavigate } from "@/libs/acl/routeProtection";
import { isUserLoggedIn } from "@/auth/utils";

import auth from "./auth";
import errors from "./errors";
import views from "./views";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...views,
    ...auth,
    ...errors,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (to.meta.noAuth === true) {
    return next();
  }

  if (!canNavigate(to)) {
    if (!isLoggedIn) {
      return next({ name: "login" });
    }
    return next({ name: "home" });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next({ name: "home" });
  }

  return next();
});

export default router;

import i18n from '@/libs/i18n'
import { Subjects } from '@/libs/acl/ability'
import management from './management'

export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/loggedin/home/index.vue'),
    meta: {
      pageTitle: i18n.t('Global.Home'),
      resource: Subjects.Public,
    },
  },
  ...management,
]

import i18n from "@/libs/i18n";
import { Subjects } from "@/libs/acl/ability";

export default [
  {
    path: "/management/users",
    name: "users",
    component: () => import("@/views/loggedin/management/users/index.vue"),
    meta: {
      pageTitle: i18n.t("Management.Users.Users"),
      resource: Subjects.Users,
    },
  },
  {
    path: "/management/customers",
    name: "customers",
    component: () => import("@/views/loggedin/management/customers/index.vue"),
    meta: {
      pageTitle: i18n.t("Management.Customers.Customers"),
      resource: Subjects.Customers,
    },
  },
  {
    path: "/management/seals/processes",
    name: "seals/processes",
    component: () => import("@/views/loggedin/management/processes/index.vue"),
    meta: {
      pageTitle: i18n.t("Management.Processes.Processes"),
      resource: Subjects.Process,
    },
  },
  {
    path: "/management/seals/processes/:id",
    name: "seals/processes-update",
    component: () => import("@/views/loggedin/management/processes/update.vue"),
    meta: {
      pageTitle: i18n.t("Management.Processes.Processes"),
      resource: Subjects.Process,
    },
  },
  {
    path: "/management/seals/processes/create/:selectedProcessType",
    name: "seals/processes-create",
    component: () => import("@/views/loggedin/management/processes/create.vue"),
    meta: {
      pageTitle: i18n.t("Management.Processes.Processes"),
      resource: Subjects.Process,
    },
  },
  {
    path: "/management/seals/countries",
    name: "seals/countries",
    component: () => import("@/views/loggedin/management/countries/index.vue"),
    meta: {
      pageTitle: i18n.t("Management.Countries.Countries"),
      resource: Subjects.Country,
    },
  },
  {
    path: "/management/seals/areas",
    name: "seals/areas",
    component: () => import("@/views/loggedin/management/areas/index.vue"),
    meta: {
      pageTitle: i18n.t("Management.Areas.Areas"),
      resource: Subjects.Area,
    },
  },
  {
    path: "/management/seals/sorts",
    name: "seals/sorts",
    component: () => import("@/views/loggedin/management/sorts/index.vue"),
    meta: {
      pageTitle: i18n.t("Management.Sorts.Sorts"),
      resource: Subjects.Sort,
    },
  },
  {
    path: "/management/seals/sealdistricts",
    name: "seals/sealdistricts",
    component: () =>
      import("@/views/loggedin/management/sealdistricts/index.vue"),
    meta: {
      pageTitle: i18n.t("Management.SealDistricts.SealDistricts"),
      resource: Subjects.SealDistricts,
    },
  },
  {
    path: "/management/seals/charges",
    name: "seals/charges",
    component: () => import("@/views/loggedin/management/charges/index.vue"),
    meta: {
      pageTitle: i18n.t("Management.Charges.Charges"),
      resource: Subjects.Charges,
    },
  },
  {
    path: "/management/accounting",
    name: "accounting",
    component: () => import("@/views/loggedin/management/accounting/index.vue"),
    meta: {
      pageTitle: i18n.t("Management.Menu.Accounting"),
      resource: Subjects.Accounting,
    },
  },
  {
    path: "/management/accounting/invoices/create/",
    name: "accounting/invoice-create",
    props: true,
    component: () =>
      import("@/views/loggedin/management/accounting/create.vue"),
    meta: {
      pageTitle: i18n.t("Management.Accounting.Accounting"),
      resource: Subjects.Accounting,
    },
  },
];
